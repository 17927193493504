import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    border: "1px solid rgba(0, 0, 0)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid rgba(0, 0, 0)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

export default function CompareTable() {
  const rowTitles = ["BASIC", "STANDARD", "BUSINESS", "PROFESSIONAL"];
  const bodyRows = [
    {
      title: "Number of pages",
      basic: "1-3 Pages",
      standard: "3-6 Pages",
      business: "6-10 Pages",
      professional: "10+ Pages",
    },
    {
      title: "Cost",
      basic: "£100 Per Page",
      standard: "£98 Per Page",
      business: "£95 Per Page",
      professional: "£90 Per Page",
    },
    {
      title: "Revisions",
      basic: "1",
      standard: "2",
      business: "2",
      professional: "3",
    },
    {
      title: "Design",
      basic: "ok",
      standard: "ok",
      business: "ok",
      professional: "ok",
    },
    {
      title: "Initial SEO",
      basic: "",
      standard: "ok",
      business: "ok",
      professional: "ok",
    },
    {
      title: "Content Creation",
      basic: "",
      standard: "ok",
      business: "ok",
      professional: "ok",
    },
    {
      title: "Speed optimization (free)",
      basic: "ok",
      standard: "ok",
      business: "ok",
      professional: "ok",
    },
    {
      title: "Website support (free for one month)",
      basic: "ok",
      standard: "ok",
      business: "ok",
      professional: "ok",
    },
    {
      title: "Security",
      basic: "ok",
      standard: "ok",
      business: "ok",
      professional: "ok",
    },
    {
      title: "Plugins",
      basic: "Pricing varies depending on the plugin you",
      standard: "Pricing varies depending on the plugin you",
      business: "Pricing varies depending on the plugin you",
      professional: "Pricing varies depending on the plugin you",
    },
    {
      title: "Domain",
      basic: "Start £12 (.co.uk, .uk, ... ) Pricing may vary for special domains",
      standard: "Start £12 (.co.uk, .uk, ... ) Pricing may vary for special domains",
      business: "Start £12 (.co.uk, .uk, ... ] Pricing may vary for special domains",
      professional: "Start £12 (.co.uk, .uk, ... ) Pricing may vary for special domains",
    },
    {
      title: "HOST Costs",
      basic: "£4 per month",
      standard: "£5 per month",
      business: "E5 per month",
      professional: "£6 per month",
    },
    {
      title: "SSL",
      basic: "Free on hosts",
      standard: "Free on hosts",
      business: "Free on hosts",
      professional: "Free on hosts",
    },
  ];
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <Typography sx={{ minWidth: "150px" }} variant="h6">
                Compare plans
              </Typography>
              <Typography sx={{ display: { xs: "none", md: "unset", lg: "unset" } }} variant="caption">
                Choose your website plan according to your organisational plan
              </Typography>
            </StyledTableCell>
            {rowTitles.map((item, idx) => {
              return (
                <StyledTableCell key={idx} align="center" sx={{ minWidth: "200px" }}>
                  <Typography variant="h6">{item}</Typography>
                  <Button sx={{ fontSize: { xs: "10px", md: "16px", lg: "16px" } }} variant="contained">
                    Choose This Plan
                  </Button>
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyRows.map((row) => (
            <StyledTableRow key={row.title}>
              <StyledTableCell component="th" scope="row">
                <Typography variant="body2">{row.title}</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.basic === "ok" ? <CheckCircleIcon /> : <Typography variant="caption"> {row.basic} </Typography>}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.standard === "ok" ? <CheckCircleIcon /> : <Typography variant="caption">{row.standard}</Typography>}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.business === "ok" ? <CheckCircleIcon /> : <Typography variant="caption">{row.business}</Typography>}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.professional === "ok" ? <CheckCircleIcon /> : <Typography variant="caption">{row.professional}</Typography>}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
