import { Box, Container } from "@mui/system";
import { Typography } from "@mui/material";
import React from "react";
import Logo from "../../assets/footer-logo.svg";
import Mail from "../../assets/mail.svg";
import Call from "../../assets/call.svg";
import location from "../../assets/location.svg";
import "./footer.css";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <Box
      className="footer"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: { lg: "75px 0px 20px 0px", xs: "75px 50px 20px 50px" },
        color: "#fff",
      }}
    >
      <Box
        sx={{
          display: { lg: "flex" },
          flexDirection: { lg: "row", xs: "column", sm: "column" },
          alignItems: { lg: "flex-start", xs: "center", sm: "center" },
          justifyContent: { lg: "center" },
        }}
      >
        <Box sx={{ flexBasis: { lg: "50%", sm: "100%", xs: "100%" } }}>
          <Box sx={{ marginBottom: { lg: "17px", sm: "30px", xs: "30px" }, textAlign: { lg: "start", sm: "center", xs: "center" } }}>
            <img src={Logo} alt="logo" />
          </Box>

          <Box
            sx={{
              marginBottom: { lg: "10px", sm: "30px", xs: "30px" },
              maxWidth: { lg: "50%" },
              textAlign: { lg: "justify", sm: "center", xs: "center" },
            }}
          >
            <Typography>
              Our dedicated team offers top-notch IT services, focusing on information security, business growth, and global market access. Get in
              touch for more information and connect to our experts.
            </Typography>
          </Box>

          <Box
            sx={{
              marginBottom: { lg: "10px" },
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
              justifyContent: { lg: "flex-start", sm: "center", xs: "center" },
            }}
          >
            <img src={Mail} alt="mail" />
            <Typography sx={{ textAlign: { lg: "justify", sm: "center", xs: "center" } }}>apslegacy.ltd@gmail.com</Typography>
          </Box>

          <Box
            sx={{
              marginBottom: { lg: "10px" },
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
              justifyContent: { lg: "flex-start", sm: "center", xs: "center" },
              flexWrap: "wrap",
              flexDirection: "row",
            }}
          >
            <img src={Call} alt="call" />
            <Box>
              <Typography sx={{ textAlign: { lg: "justify", sm: "center", xs: "center" } }}>+44 20 8333 7881</Typography>
              <Typography sx={{ textAlign: { lg: "justify", sm: "center", xs: "center" } }}>+44 77 7069 8835(hotline)</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", marginRight: "10px", justifyContent: { lg: "flex-start", sm: "center", xs: "center" } }}>
            <img src={location} alt="location" />
            <Typography sx={{ textAlign: { lg: "justify", sm: "center", xs: "center" } }}> 40 Rusland Park, London UK</Typography>
          </Box>
        </Box>

        <Box sx={{ flexBasis: { lg: "40%" } }}>
          <Box sx={{ display: "flex", justifyContent: { lg: "space-around", sm: "space-evenly", xs: "space-evenly" } }}>
            <Box sx={{ flexBasis: { lg: "17%" } }}>
              <Box sx={{ display: { lg: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" } }}>
                <Box sx={{ marginBottom: "50px", fontSize: "24px", fontWeight: 700 }}>MAIN</Box>
                <Box sx={{ marginBottom: "50px", fontSize: "16px", fontWeight: 500 }}>
                  <Link to="/">HOME</Link>
                </Box>
                <Box sx={{ marginBottom: "50px", fontSize: "16px", fontWeight: 500 }}>
                  <Link to="/services">SERVICES</Link>
                </Box>
                <Box sx={{ marginBottom: "50px", fontSize: "16px", fontWeight: 500 }}>
                  <Link to="/pricing">PRICING</Link>
                </Box>
                <Box sx={{ marginBottom: "50px", fontSize: "16px", fontWeight: 500 }}>
                  <Link to="/about">ABOUT</Link>
                </Box>
              </Box>
            </Box>

            <Box sx={{ flexBasis: { lg: "17%" } }}>
              <Box sx={{ display: { lg: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" } }}>
                <Box sx={{ marginBottom: "50px", fontSize: "24px", fontWeight: 700 }}>SOCIAL</Box>
                <Box sx={{ marginBottom: "50px", fontSize: "16px", fontWeight: 500 }}>
                  <Link to="https://instagram.com/aps.legacy?igshid=MzRlODBiNWFlZA==" target="_blank">
                    INSTAGRAM
                  </Link>
                </Box>
                <Box sx={{ marginBottom: "50px", fontSize: "16px", fontWeight: 500 }}>
                  <Link to="mailto:apslegacy.ltd@gmail.com" target="_blank">
                    GMAIL
                  </Link>
                </Box>
                <Box sx={{ marginBottom: "50px", fontSize: "16px", fontWeight: 500 }}>
                  <Link to="https://www.linkedin.com/company/apslegacy/" target="_blank">
                    LINKEDIN
                  </Link>
                </Box>
                <Box sx={{ marginBottom: "50px", fontSize: "16px", fontWeight: 500 }}>
                  <Link to="https://t.me/apsignalslegacy" target="_blank">
                    TELEGRAM
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box>© {new Date().getFullYear()} APSLegacy All rights reserved</Box>
      </Box>
    </Box>
  );
}

export default Footer;
